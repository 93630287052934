import React from 'react';
import { AlertTriangle } from 'lucide-react';

const SessionExpiredModal = ({ onReconnect }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <div className="flex items-center mb-4 text-accent">
          <AlertTriangle className="h-6 w-6 mr-2" />
          <h2 className="text-lg font-semibold">Votre session a expiré</h2>
        </div>
        <p className="mb-4 text-neutral">
          Veuillez vous connecter à nouveau pour continuer à utiliser l'application.
        </p>
        <button
          onClick={onReconnect}
          className="w-full bg-focus hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded transition duration-200"
        >
          Se connecter
        </button>
      </div>
    </div>
  );
};

export default SessionExpiredModal;